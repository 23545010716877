module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.npg.ma"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"NPG Imprimerie","short_name":"npg","description":"NPG Imprimerie offre des services d'impression professionnels de haute qualité à Tanger. Nous sommes votre partenaire d'impression de confiance pour tous vos besoins commerciaux. Contactez-nous dès maintenant pour en savoir plus sur nos services d'impression personnalisés.","start_url":"/","background_color":"#ffffff","theme_color":"#FF5D22","orientation":"portrait","display":"standalone","icon":"/opt/build/repo/src/images/logo-web.png","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6f2b6f340d8a45c22c0405e706cd06a8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/"],"workboxConfig":{"globPatterns":["**/*.{js,jsx,ts,tsx,html,css,json}"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-270TEPNZDW","UA-132323082-1"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
